.tab-bar-container {
    .tab-bar li {
        text-decoration: none;
        display: inline-block;

        a {
            padding: 1em;
            border-radius: 5px;
            margin: 1em;
            border: #d0d0d0 1px solid;
            text-decoration: none;
            color: #c3c3c3;
            display: inline-block;

            &:hover, &.active {
                color: #000;
                background-color: #FFF;
                box-shadow: #cecece 0 0.3em 0.5em;
                cursor: pointer;
            }
        }
    }
}