.footer-container {
    background-image: url(./images/footer-img.png);
    background-size: 100% 100%;
    text-transform: uppercase;
    color: #fff;
    padding: 1.5em;
    margin-top: 4em;

    p.code-reference, p.copyright {
        text-transform: initial;
        font-size: 0.5em;
        font-style: italic;
    }
}