.section-title-container {
    background-image: url(./images/section-title-img.png);
    background-size: 100% 100%;
    text-transform: uppercase;
    color: #fff;
    padding: 1em 1.5em 3em 1.5em;
    margin-top: 8em;
    margin-bottom: 4em;

    h1 {
        color: #fff !important;
    }
}