.header-container {
    background-image: url(./images/header-img.png);
    background-size: 100% 100%;
    text-transform: capitalize;
    color: #fff;
    padding: 1.5em;

    img.personal-photo {
        border-radius: 100%;
        width: 8em;
        height: 8em;
        max-width: 8em;
        max-height: 8em;
        min-width: 8em;
        min-height: 8em;
        display: inline-block;
        filter: saturate(70%);

        &:hover {
            filter: none;
            filter: drop-shadow(0 0 0.5em #00000050);
            filter: hue-rotate(360);
        }
    }
}

@media screen and (max-width: 800px) {
    .header-container {
        background-image: url(./images/header-img-mobile.png);
    }
}