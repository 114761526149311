.animated-icon-container {
    width: 2em;
    height: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}