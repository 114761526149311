.referral-option-container {
    background-size: 100% 100%;
    color: #555;
    padding: 1.8em;
    margin: 1.5em;
    width: 22vw;
    min-width: 230px;
    min-height: 15vw;
    font-size: 0.7em;
    position: relative;

    h2 {
        font-size: 1.4em;
        font-weight: 300;
        text-decoration: none;
    }

    .primary-action, .secondary-action {
        display: inline-block;
        padding: 0.4em 1em;
        margin: 0.6em 0;
        border: 0;
        border-radius: 1em;
        color: #FFFFFF;
        text-decoration: none;
        font-size: 1.1em;
    }

    .primary-action {
        background-color: #D26B73;
    }

    .secondary-action {
        background-color: #28B8CE;
    }

    .referral-option-content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: justify;
        background: #fff;
        padding: 1.2em;
        margin: 0 auto;
        min-height: 14vw;
        border-radius: 0.8em;
        referral-option-shadow: 0 0 0.3em rgba(0,0,0,0.16);

        .separator {
            max-width: 80px;
            margin-top: 0.8em;
        }

        .referral-option-text {
            min-height: 7.8em;
        }

        .referral-option-html-text {
            min-height: 4em;
        }
    }

    .brand-logo {
        padding: 0.8em 0.8em 0 0.8em;
        border-bottom: 1px solid rgb(232,232,232);

        img {
            width: 80%;
            max-height: 80px;
            object-fit: contain;
        }
    }

    .referral-link {
        padding: 0.8em;
        text-align: center;
        word-wrap: break-word;
        font-size: 0.9em;
        color: #D26B73;
        border-radius: 1em;
        box-shadow: 0 0 0.1em rgba(0,0,0,0.16);
    }
}