.maximize-container {
    position: absolute;
    width: 1em;
    height: 1em;
    top: 0.5em;
    right: 0.8em;
    background-size: contain;
    cursor: pointer;

    &.minimized {
        background-image: url(./images/maximize.png);
    }

    &.maximized {
        background-image: url(./images/minimize.png);
    }
}