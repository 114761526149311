.opening-container {
    color: #555;
    padding: 1.8em;
    margin: 1em auto;
    width: 80%;
    min-width: 230px;
    font-size: 0.8em;
    position: relative;

    h2 {
        font-size: 1.4em;
        font-weight: 300;
        text-decoration: none;
    }

    .opening-content {
        position: relative;
        background: #fff;
        padding: 1.2em;
        margin: 0 auto;
        border-radius: 0.8em;
        box-shadow: 0 0 0.3em rgba(0,0,0,0.16);
    }
}